<template>
  <div :class="['sw-account__info', className]">
    <div :class="['sw-account__info--heading', { edit: edit }]">
      <h6>
        <img
          src="@/assets/images/partner/settings/email.svg"
          alt="general info"
        />General Information
      </h6>
      <LxpButton
        v-if="showSave"
        :variant="edit ? 'secondary1' : 'link-secondary1'"
        @click="onEdit"
        >{{ edit ? "Save" : "Edit" }}</LxpButton
      >
    </div>
    <div class="sw-account__info--detail">
      <div class="sw-account__info--detail" v-if="edit">
        <b-row class="mb-3">
          <b-col>
            <LxpInput
              label="Username"
              disabled
              v-model="generalInfoForm.username"
            />
          </b-col>
          <b-col>
            <LxpInput
              label="Email"
              :maxlength="150"
              v-model="generalInfoForm.email"
              disabled
            />
          </b-col>
        </b-row>
        <b-row>
          <ValidationObserver ref="general-info-observer">
            <div class="sw-account__info--form">
              <ValidationProvider
                name="first name"
                rules="required"
                v-slot="{ errors }"
              >
                <LxpInput
                  label="First Name"
                  :maxlength="150"
                  :hasError="errors[0] ? true : false"
                  :supportText="errors[0] ? errors[0] : ''"
                  v-model="generalInfoForm.first_name"
                  @input="onInput"
                />
              </ValidationProvider>
              <ValidationProvider
                name="last name"
                rules="required"
                v-slot="{ errors }"
              >
                <LxpInput
                  label="Last name"
                  :maxlength="150"
                  :hasError="errors[0] ? true : false"
                  :supportText="errors[0] ? errors[0] : ''"
                  v-model="generalInfoForm.last_name"
                  @input="onInput"
                />
              </ValidationProvider>
            </div>
          </ValidationObserver>
        </b-row>
      </div>
      <div class="sw-account__info--detail--view" v-else>
        <div class="sw-account__info--detail__row">
          <div>
            <label>Username</label>
            <p>{{ generalInfo.username }}</p>
          </div>
          <div>
            <label>Email</label>
            <p>{{ generalInfo.email }}</p>
          </div>
        </div>
        <div class="sw-account__info--detail__row">
          <div>
            <label>First Name</label>
            <p>{{ generalInfo.first_name ? generalInfo.first_name : "-" }}</p>
          </div>
          <div>
            <label>Last Name</label>
            <p>{{ generalInfo.last_name ? generalInfo.last_name : "-" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { LxpButton, LxpInput } from "didactica";
import { ValidationObserver } from "vee-validate";
import { ValidationProvider } from "vee-validate/dist/vee-validate.full.esm";
export default {
  components: {
    LxpButton,
    LxpInput,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    isEdit: {
      type: Boolean,
      required: true
    },
    generalInfo: {
      type: Object,
      required: true
    },
    showSave: {
      type: Boolean,
      default: false
    },
    className: {
      type: String
    }
  },
  data() {
    return {
      generalInfoForm: {},
      edit: false
    };
  },
  watch: {
    isEdit(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.edit = this.isEdit;
      }
    },
    generalInfo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setInfo();
      }
    }
  },
  created() {
    this.edit = this.isEdit;
    this.setInfo();
  },
  methods: {
    onEdit() {
      if (this.edit) {
        this.$refs["general-info-observer"].validate().then(res => {
          if (res) {
            this.$emit("save", this.generalInfoForm);
          }
        });
      } else {
        this.edit = !this.edit;
      }
      this.$emit("update:isEdit", this.edit);
    },
    setInfo() {
      this.generalInfoForm = JSON.parse(JSON.stringify(this.generalInfo));
    },
    onInput() {
      if (!this.showSave) {
        this.$emit("form-update", this.generalInfoForm);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.sw-account__info {
  @include account-style();
  .sw-account__info--heading {
    @include horizontal-space-between;
    &.edit {
      margin-bottom: 16px;
    }
  }
  .sw-account__info--detail {
    .sw-account__info--form {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1.5rem;
      span {
        flex: 1;
      }
    }
    .sw-account__info--detail__row {
      margin-bottom: 16px;
      display: flex;
      > div {
        padding: 8px 16px;
        width: 50%;
        label {
          @include label-medium;
          color: $brand-neutral-300;
        }
        p {
          margin-bottom: 0;
          @include body-regular;
        }
      }
    }
  }
}
</style>
